<template>
  <b-card v-if="newsData">
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="4"
        class="d-flex justify-content-between"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start align-items-center">
          <b-avatar
            :src="newsData ? newsData.thumbnail : 'https://via.placeholder.com'"
            size="114px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ newsData.title }}
              </h4>

<!--              <span class="card-text">{{ newsData.description }}</span>-->
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-news-edit', params: { id: newsData.id } }"
                variant="danger"
              >
                Edit
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="deleteNews(newsData.id)"
              >
                Delete
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col>
        <div class="d-flex justify-content-end align-items-center h-100">
          <video
            width="320"
            height="240"
            controls
            :poster="newsData.thumbnail"
          >
            <source
              :src="newsData.video_link"
              type="video/mp4"
            >
            <source
              src=""
              type="video/ogg"
            >
            Your browser does not support the video tag.
          </video>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
    BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import router from '@/router'

// repository
import NewsRepository from '@/abstraction/repository/newsRepository'

const newsRepository = new NewsRepository()

export default {
    components: {
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
    },
    props: {
        newsData: {
            // type: Object,
            required: true,
        },
    },
    methods: {
        async deleteNews(newsId) {
            await newsRepository.delete(newsId)
            router.push({ name: 'apps-news-list' })
        },
    },
}
</script>

<style></style>
