<template>
  <div v-if="form">
    <template>
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <news-view-news-info-card :news-data="form" />
        </b-col>
      </b-row>
      <b-tabs
        v-if="form"
        pills
      >
        <!-- Tab: Account -->
        <b-tab>
          <template #title>
            <span>
              <i class="WMi-info-circled-alt" />
            </span>
            <span class="d-none d-sm-inline text-capitalize">Details</span>
          </template>
          <b-row>
            <b-col cols="12">
              <b-card>
                <b-card-body class="text-capitalize">
                  <b-card-title>Description</b-card-title>
                  <b-card-sub-title>{{ form.description }}</b-card-sub-title>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BTabs,
    BTab,
    BCardSubTitle,
    BCardTitle,
    BCardBody,
} from 'bootstrap-vue'
import router from '@/router'
import NewsRepository from '@/abstraction/repository/newsRepository'
import NewsViewNewsInfoCard from './NewsViewNewsInfoCard.vue'
import NewsViewTabData from './NewsViewTabData.vue'

// repository

const newsRepository = new NewsRepository()

export default {
    components: {
        BRow,
        BCol,
        BAlert,
        BLink,
        BCard,
        BTabs,
        BTab,
        BCardSubTitle,
        BCardTitle,
        BCardBody,
        // Local Components
        NewsViewNewsInfoCard,
        NewsViewTabData,
    },
    setup() {
        const form = ref(null)
        const newsId = ref(router.currentRoute.params.id)
        onMounted(async () => {
            if (newsId.value) {
                const resource = await newsRepository.show(newsId.value)
                form.value = resource
            }
        })
        return {
            form,
        }
    },
}
</script>

<style></style>
